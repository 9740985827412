/*
 * Copyright 2019 Transposit Corporation. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export function chompSlash(string: string) {
  return string.replace(/\/$/, "");
}

// https://my-app.transposit.io?clientJwt=...needKeys=... -> https://my-app.transposit.io
export function hereWithoutSearch(): string {
  return `${window.location.origin}${window.location.pathname}`;
}

export function formUrlEncode(data: { [key: string]: string }): string {
  return Object.keys(data)
    .map(key => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
    })
    .join("&");
}
